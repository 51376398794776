import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import Image from '../components/Image';

const LeCalendrier = props => (
  <Layout>
    <Helmet>
      <title>Calendrier - Palette Sartoise</title>
      <meta name="description" content="Calendrier des interclubs" />
    </Helmet>

    <div id="main" className="wrapper style1">
      <div className="container">
        <header className="major">
          <h2>Le Calendrier des interclubs</h2>
          <p>L’occasion pour les joueurs de la Palette Sartoise d’affronter les joueurs des autres clubs de la province de Namur (et de les battre évidemment !).</p>
        </header>

        <section id="content">
          <p>
          <Image alt="Calendrier - Palette Sartoise" filename="calendrier-interclubs.jpg" />
          </p>
          <p>
          <iframe src="https://www.vedrinamur.be/dynam/calclub.php?Indice=N188&amp;Journee=A" width="100%" height="4000" allowtransparency="true" frameborder="0" marginwidth="0" marginheight="0" scrolling="no"></iframe>
          </p>
        </section>
      </div>
    </div>
  </Layout>
)

export default LeCalendrier
